.TitleContact{
    -webkit-animation: slide-in-left 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      animation: slide-in-left 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;  
  }
.contactCard{
    opacity : 0;
    -webkit-animation: fade-in 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) 1s both;
	        animation: fade-in 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) 1s both;
}
.contactCard2{
    opacity : 0;
    -webkit-animation: fade-in 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) 1.2s both;
	        animation: fade-in 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) 1.2s both;
}