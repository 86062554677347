ul{
  list-style: none;
}

a {
  text-decoration: none; 
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); 
}

.ContentPage{
  position : fixed;
  z-index : -1;
  background-color: black;
  /* background: linear-gradient(25deg, #9d174d 0%, #0f172a 80%); */
}
