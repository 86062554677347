#reactjs{
    animation : spin linear;
    animation-iteration-count: infinite;
    animation-duration: 5000ms;
}
@keyframes spin{
    0%{

    }
    100%{
        transform : rotate(-360deg);
    }
}
/* 
#html5{
    animation: rotate-vert-center linear infinite both;
    animation-duration: 5000ms;
}
@keyframes rotate-vert-center {
    0% {
      -webkit-transform: rotateY(0);
              transform: rotateY(0);
    }
    100% {
      -webkit-transform: rotateY(360deg);
              transform: rotateY(360deg);
    }
  }
   */

   #maksnobel{
    border-radius: 100%;
   }