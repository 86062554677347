.Titleloisir{
  -webkit-animation: slide-in-left 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-left 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;  
}
.underTitleLoisir{
  width : 50px;
  height :1px;
  animation : lighteffect linear 10000ms;
  animation-iteration-count: infinite;
  animation-delay: 1s;
}
.cardLoisir{
    opacity : 0;
    -webkit-animation: fade-in 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) 1s both;
	        animation: fade-in 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) 1s both;
}
.cardLoisir2{
    opacity : 0;
    -webkit-animation: fade-in 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) 1.2s both;
	        animation: fade-in 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) 1.2s both;
}
.cardLoisir3{
    opacity : 0;
    -webkit-animation: fade-in 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) 1.5s both;
	        animation: fade-in 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) 1.5s both;
}
.Titleloisir2{
  -webkit-animation: slide-in-left 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1.8s both;
    animation: slide-in-left 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1.8s both;  
}
.underTitleLoisir2{
  width : 50px;
  height :1px;
  animation : lighteffect linear 10000ms;
  animation-iteration-count: infinite;
  animation-delay: 2.8s;
}
.cardLoisir4{
  opacity : 0;
  -webkit-animation: fade-in 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) 2.8s both;
        animation: fade-in 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) 2.8s both;
}
.buttonEffect{
  background:
  linear-gradient(-45deg, #000  40%, #fff 0 0%, #000  0) right/0% 0% no-repeat,
  linear-gradient(-45deg, #0000 0%, #9d174d 0 60%, rgb(15, 23, 42) 0) right/300% 100% no-repeat;
  -webkit-background-clip: text, padding-box;
  background-clip: text, padding-box;
}
.buttonEffect:hover{
  background-position: left;
  transition: 0.8s
}
.cardEffect{
  background:
    linear-gradient(-45deg, #000  40%, #fff 0 60%, #000  0) right/350% 100% no-repeat,
    linear-gradient(-45deg,rgba(255, 255, 255,0) 40%, rgba(255, 255, 255, 0.05) 45%, rgba(255, 255, 255, 0.05) 0 50%, rgba(255, 255, 255, 0) 60%,rgba(255, 255, 255, 0) 0) right/350% 100% no-repeat;
  -webkit-background-clip: text, padding-box;
  background-clip: text, padding-box;
  animation : backgroundMove 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation-duration: 20s;
  animation-delay: 4s;
  animation-iteration-count: infinite;
}
@keyframes backgroundMove{
  0%{  
    background-position-x: right;
  }
  25%{
    background-position-x: left;
  }
  100%{
    background-position-x: left;
  }
}

@keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  