.homeContainer{
    background-color: #0f172a;
    width: 90%;
    margin : auto;
    border-radius : 10px;
}

.HomeDivOne{
    width : 100%;
    display : flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.hello{
    margin-top : 5px;
    padding : 5px;
    display : flex;
    justify-content : center;
    font-size : 18px;
    font-weight: bold;
    -webkit-animation: slide-in-left 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1.2s both;
	        animation: slide-in-left 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1.2s both;
}
@keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
              transform: translateX(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
}
.test{
    vertical-align: center;
}

.MyName{
    -webkit-animation: slide-in-right 1s both;
    animation: slide-in-right 1s both;
}

@keyframes slide-in-right {
    0% {
      -webkit-transform: translateX(1000px);
              transform: translateX(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
.ImageHome{
    border-radius: 50% 50% 50% 50% ;
    -webkit-animation: fade-in 3s cubic-bezier(0.390, 0.575, 0.565, 1.000) 1s both;
	        animation: fade-in 3s cubic-bezier(0.390, 0.575, 0.565, 1.000) 1s both;
}

  
@keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
.circle{
    animation: lumiere linear;
    animation-duration : 10000ms;
    animation-iteration-count: infinite;
}
.contentanim{
    position : fixed;
    z-index : -1;
    width : 640px;
    height : 100%;
    overflow : hidden;
}
#lum1{
    animation-delay: 0s;
    animation-duration : 9000ms;
}
#lum2{
    animation-delay: 1s;
    animation-duration : 10000ms;
}
#lum3{
    animation-delay: 2s;
    animation-duration : 8000ms;
}
#lum4{
    animation-delay: 3s;
    animation-duration : 8900ms;
}
#lum5{
    animation-delay: 4s;
    animation-duration : 13000ms;
}
#lum6{
    animation-delay: 2.5s;
    animation-duration : 11000ms;
}
#lum7{
    animation-delay: 0.5s;
    animation-duration : 7000ms;
}

@keyframes lumiere {
    0%{
        opacity : 0;
        background-color : rgb(255, 255, 255);
    }
    50%{
        opacity : 1;
        box-shadow: 0px 0px 50px 10px white;
        
    }
    100%{
        opacity : 0;
        transform: translate(500px, 1000px);
    }
}
.homeRecruterCard{
    -webkit-animation: fade-in 3s cubic-bezier(0.390, 0.575, 0.565, 1.000) 1.4s both;
            animation: fade-in 3s cubic-bezier(0.390, 0.575, 0.565, 1.000) 1.4s both;
}

::-webkit-scrollbar{
    width : 8px;
}
::-webkit-scrollbar-thumb{
    opacity : 0.2;
    background-color: rgba(29, 118, 190, 0.708);
    border-radius: 5px;
}

.buttonEffect {
    width : 100%;
    font-size: 15px;
}

@media (min-width:1024px) {
    .hello{
    display : flex;
    justify-content: center;
    width : 50%;
    margin : 0% 25%
    }
    .HomeContent{
        padding-top : 10%
    }
    .homeRecruterCard{
        clip-path: polygon(var(--d) 3px,var(--d) 0,100% 0,100% 100%,var(--d) 100%,var(--d) calc(100% - 3px));
        transform: perspective(1000px) rotateY(0deg) rotateX(0deg);
        transition: 0.4s,color 0.4s 0.4s,background-color 0.4s 0s;
     
     }
    .homeRecruterCard:hover{ 
        background-color: rgb(15, 23, 42, 0.5);
        color: #fff;
        clip-path: polygon(0px 3px,var(--d) 0,100% 0,100% 100%,var(--d) 100%,0  calc(100% - 3px));
        transform: perspective(1000px) rotateY(5deg) rotateX(5deg);
        transition: 0,clip-path 0.4s 0.4s,transform 0.4s 0.4s;
        background-position: left;
    
    }
}