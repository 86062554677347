.bardisplay{
    width : 100%;
    display : flex;
    justify-content: center;
}
.barcontent{
    overflow : hidden;
    height : 1px;
    width : 450px;
    display : flex;
}
.barexperience{
    width : 50px;
    height :1px;
    animation : lighteffect linear 10000ms;
    animation-iteration-count: infinite;
    animation-delay: 2s;
}
.barexperience2{
    width : 50px;
    height :1px;
    animation : lighteffect linear 10000ms;
    animation-iteration-count: infinite;
    animation-delay: 4.5s;
}
.barexperience3{
    width : 50px;
    height :1px;
    animation : lighteffect linear 10000ms;
    animation-iteration-count: infinite;
    animation-delay: 6s;
}

@keyframes lighteffect {
    0%{
                background : linear-gradient(90deg, rgba(255,255,255,0) 0%, rgb(234, 223, 223) 100%);
                transform : translate(-50px, 0px);
    }
    49%{
                transform : translate(450px, 0px);
                background : linear-gradient(90deg, rgba(255,255,255,0) 0%, rgb(234, 223, 223) 100%);
    }
    50%{
        transform : translate(450px, 0px);
        background : linear-gradient(280deg, rgba(255,255,255,0) 0%, rgb(234, 223, 223) 100%);
}
    100%{
                transform : translate(-50px, 0px);
                background : linear-gradient(280deg, rgba(255,255,255,0) 0%, rgb(234, 223, 223) 100%);
    }
}