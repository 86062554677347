/* nav.scrolled{      
        opacity : 0;
      } */
.IconContainer{
  padding : 0.5rem;
  height : 4rem;
}

.IconHeader{
  height : 3rem;
  width : 3rem;
  padding : 0.25rem;
  color:#f8fafc;
  border-width : 2px;
  border-radius: 50%;
  border-color: #f8fafc;
}

.IconHeader:hover{
  background-color:#9d174d;
  border-color: #9d174d;
  transition-duration: 300ms;
}
/* .IconHeader:active{
  border-bottom: #9d174d;
  background-color: #9d174d;
} */